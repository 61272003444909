import type { MenuListItem } from "@naf/teamscheme";
import { AccountCircle } from "@styled-icons/material/AccountCircle";
import { AccountCircle as AccountCircleOutlined } from "@styled-icons/material-outlined/AccountCircle";
import { Lock } from "@styled-icons/material-outlined/Lock";
import { LockOpen } from "@styled-icons/material/LockOpen";
import { Logout } from "@styled-icons/material/Logout";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { externalLinkEvent } from "../../tracking/externalLinkEvent";
import { EVENTS } from "../../tracking/EVENTS";
import { loginWithRedirect, logout } from "../../auth/auth0Client";
import { useCustomer } from "../../auth/CustomerContext";
import { NAF_NO_MITT_NAF_URL } from "../../shared/NafnoUrls";
import { getLocationString } from "../../shared/utils/Location";
import type { MaybeOrderedMenuListItem } from "./HeaderObjects";
import type { Destination } from "../../contract/routing/Destination";
import { useAuth } from "../../auth/AuthContext";

function createLoggedInHeaderProps(): MaybeOrderedMenuListItem {
  const props: MenuListItem = {
    key: "MittNAF",
    text: "Mitt NAF",
    icon: {
      icon: <AccountCircleOutlined size={24} />,
      hoverIcon: <AccountCircle size={24} />,
    },
    href: NAF_NO_MITT_NAF_URL,
    trackEvent: externalLinkEvent(NAF_NO_MITT_NAF_URL),
  };
  return props;
}

function createLoginHeaderProps(
  destination: Destination<unknown>,
): MaybeOrderedMenuListItem {
  const text = "Logg inn";
  const props: MenuListItem = {
    key: "LoggInn",
    text,
    icon: {
      icon: <Lock size={24} />,
      hoverIcon: <LockOpen size={24} />,
    },
    trackEvent: { ...EVENTS.HEADER.LOG_IN, label: text },
    onClick: () => {
      loginWithRedirect({
        targetUrl: getLocationString(destination.to),
        locationState: destination.state,
      });
    },
  };
  return props;
}

function createLogoutHeaderProps(): MaybeOrderedMenuListItem {
  const text = "Logg ut";
  const props: MaybeOrderedMenuListItem = {
    key: "LoggUt",
    text,
    trackEvent: { ...EVENTS.HEADER.LOG_OUT, label: text },
    icon: {
      icon: <Logout size={24} />,
    },
    onClick: logout,
    order: 1,
  };
  return props;
}

export function useAuthHeaderElements(loginDestination?: Destination<unknown>) {
  const { isInitialized } = useAuth();
  const customer = useCustomer();
  const location = useLocation();
  const loginTargetLocation = loginDestination || {
    to: location,
    state: location.state,
  };

  return useMemo(() => {
    if (!isInitialized) {
      const nothing: MenuListItem = {
        key: "NotInitialized",
        text: <div style={{ width: "100px" }} />,
      };
      return [nothing];
    }
    if (customer.isLoading) {
      const loading: MenuListItem = {
        key: "HenterKonto",
        text: "Henter konto",
        isLoading: true,
      };
      return [loading];
    }
    if (customer.result && customer.isLoggedIn) {
      const loggedIn = createLoggedInHeaderProps();
      const logout = createLogoutHeaderProps();
      return [loggedIn, logout];
    }
    if (customer.isLoggedIn) {
      return [createLogoutHeaderProps()];
    }
    return [createLoginHeaderProps(loginTargetLocation)];
  }, [
    isInitialized,
    customer.isLoading,
    customer.isLoggedIn,
    customer.result,
    loginTargetLocation,
  ]);
}
