import { createContext, useContext, useMemo } from "react";
import { Schema } from "#vehicle-contract/common/lib/common";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { capitalizeFirst } from "../utils";
import type {
  CustomUpdateCallback,
  FieldSave,
  RawFieldValue,
} from "./FieldSave";
import type { ContractViewModel, FieldValue } from "./model/ContractViewModel";
import type { ContractingParty } from "#vehicle-contract/common/lib/model/contract/ContractingParty";

export interface IContractContext {
  contract: ContractViewModel;
  role: Role;
  isInitiator: boolean;
  applyContractUpdates(
    updates: (prev: ContractViewModel) => ContractViewModel,
  ): void;
  onChange(contract: ContractViewModel): void;
  saveField(
    section: string,
    field: string,
    value:
      | RawFieldValue
      | FieldValue /* Conditoon questions are saved as FieldValue */,
    fieldValue: FieldValue | null,
    update?: CustomUpdateCallback,
  ): Promise<void>;
  saveFields(fields: FieldSave[]): Promise<void>;
  enqueueChanges(fields: FieldSave[]): void;
}

export const ContractContext = createContext<IContractContext | null>(null);

export function useContractContext() {
  const contractContext = useContext(ContractContext);

  if (!contractContext) throw new Error("No ContractContext found!");

  return contractContext;
}

export function useOwnSection(): ContractingParty | null {
  const contract = useContract();
  const role = useRole();
  return role === Role.Buyer ? contract.buyer : contract.seller;
}

export function useRole() {
  const contractContext = useContractContext();

  const { role } = contractContext;

  if (role === null) throw new Error("No Role loaded!");

  return role;
}

export function useContract() {
  const contractContext = useContractContext();

  const { contract } = contractContext;

  if (!contract) throw new Error("No Contract found!");

  return contract;
}

export function useSchema() {
  const { schemaType } = useContract();

  return useMemo(() => new Schema(schemaType), [schemaType]);
}

export const ROLE_NAMES = {
  [Role.Buyer]: "kjøper",
  [Role.Seller]: "selger",
};

export function getRoleName(role: Role) {
  const roleName = ROLE_NAMES[role];
  return {
    TitleCase: capitalizeFirst(roleName),
    regularCase: roleName,
  };
}

export function useRoleName() {
  const role = useRole();
  const otherRole = useOtherRole();

  return useMemo(() => {
    const self = getRoleName(role);
    const other = getRoleName(otherRole);

    return {
      Other: other.TitleCase,
      other: other.regularCase,
      Self: self.TitleCase,
      self: self.regularCase,
    };
  }, [role, otherRole]);
}

export function useOtherRole() {
  const role = useRole();
  return getOtherRole(role);
}

export function getOtherRole(role: Role) {
  return role === Role.Seller ? Role.Buyer : Role.Seller;
}

export function useIsInitiator() {
  const contractContext = useContractContext();

  const { isInitiator } = contractContext;

  if (typeof isInitiator !== "boolean")
    throw new Error("No IsIntiiator loaded!");

  return isInitiator;
}
